// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();


import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom';
import configureStore from './redux/configureStore';
import App from './components/App';
import style  from './scss/style.scss'; 
// require('./scss/style.scss');

const state = window.__STATE__;
delete window.__STATE__;
const store = configureStore(state)
window.gAppStore = store;
render(
  <Provider store={store} >
    <BrowserRouter  >
      <App />
    </BrowserRouter>
  </Provider>,
  document.querySelector('#app')
)
