import React, { Component, Fragment, useEffect, useState } from "react";
import { ButtonGroup } from "./../Helper";
import { Link, withRouter } from "react-router-dom";
import { fetchData, formatDay, iAxios } from "../../utils";
import moment from 'moment';

const Actions = {
  CheckedIn:'Checked In',
  CheckedOut:'Checked Out',
  Lunch:'Lunch',
  Active:'Active',
  // Leave:'Out',
  Busy:'Busy'
};

const ActionMap = {
  'Active' : 'In',
  'Checked In' : 'In',
  'Checked Out': 'Out',
  // 'Leave':'Out',
  'Lunch': 'Out',
  'Busy':'In',
}
  
const UsersStatus = ({ user }) => {

  const [statusName, setStatusName] = useState('CheckIn');
  const [buttonColor, setButtonColor] = useState('w3-grey');
  const [action, setAction] = useState(null);

  const fetchData =  ()=>{
    iAxios.get('/api/v1/user-log').then(resp => {
      if (!resp?.data?.data) {
        return;
      }
      const c = resp.data.data.action;
      setAction(resp.data.data);
      setStatus(c);
    });
  }

  const noCheckinToday = false;
  useEffect(() => {
    fetchData();
    const timer = setInterval(fetchData,600*1000);
    
    window.addEventListener("storage", (ev)=>{
      console.log('stroage',ev);
    });
    return ()=>{
      clearInterval(timer)
    }
  }, [1])

const setStatus = (action)=>{  
    if (action === Actions.CheckedIn || action === Actions.Active) {
      setStatusName(Actions.Active);
      setButtonColor('w3-green');
    } else if (action === Actions.Busy) {
      setStatusName('Busy');
      setButtonColor('w3-orange')
    } else {
      setStatusName('Check In');
      setButtonColor('w3-grey');
    }  
}

  const onchangeStatus = (evt) => { 
   
    if (!ActionMap[evt]) {
      alert('Invalid Type' + evt);
      return;
    }

    iAxios
      .post("/api/v1/user-log", { 
        action_group: ActionMap[evt] ? ActionMap[evt]:evt,
        action: evt,
      })
      .then((resp) => { 
        if (!resp.data.success){
          alert(resp.data.message);
          return;
        }

        const c = resp.data.data.action;
        setAction(resp.data.data);
        setStatus(c);
        localStorage.setItem('checkin-status',JSON.stringify(resp.data.data));
      })
      .catch((err) => {
        if (err.response.status == 401){
        alert('Unable to checkin, please login');
        document.location.reload()
        }
        console.log("err: ", err);
      });
  }
  const btnCls = 'w3-button w3-padding-small w3-round ';

  let secs = 0;
  if (action) {
    secs = Math.floor((Date.now() - action.action_time) / 1000);
  }


  return (
    <Fragment>
      {user ? 
      <div style={{marginTop:'4px'}}>
        {statusName === Actions.Active && (
          <div className="w3-dropdown-hover w3-round " style={{zIndex:10000}}>
            <button className={`${btnCls} ${buttonColor}`}    >
              {statusName}
              <i className="ion-chevron-down" style={{ fontSize: "10px", marginLeft: "10px" }} ></i>
            </button>
            <div className="w3-dropdown-content w3-bar-block ">
              <button onClick={e => onchangeStatus(Actions.Busy)} className="w3-bar-item w3-button">Busy </button>
              <button onClick={e => onchangeStatus(Actions.Lunch)} className="w3-bar-item w3-button" > Lunch </button>
              <button onClick={e => onchangeStatus(Actions.CheckedOut)} className="w3-bar-item w3-button" > CheckOut
              </button>
            </div>
          </div>
        )}
        {statusName === Actions.Busy && (
          <div className="w3-dropdown-hover w3-round">
            <button className={`${btnCls} ${buttonColor}`}    >
              {statusName}
              <i className="ion-chevron-down" style={{ fontSize: "10px", marginLeft: "10px" }} ></i>
            </button>
            <div className="w3-dropdown-content w3-bar-block  ">
              <button onClick={e => onchangeStatus(Actions.Active)} className="w3-bar-item w3-button">Active</button>
              <button onClick={e => onchangeStatus(Actions.Lunch)} className="w3-bar-item w3-button" > Lunch </button>
              <button onClick={e => onchangeStatus(Actions.CheckedOut)} className="w3-bar-item w3-button" > CheckOut</button>
            </div>
          </div>
        )}
        { (!action || action.action_group != 'In' ) && (
          <>
            <button className={`${btnCls} ${buttonColor} w3-margin-right`} onClick={e => onchangeStatus(Actions.Active)} >
              {statusName}
            </button>
            { noCheckinToday &&  <span>
              <i className="ion w3-large ion-alert-circled w3-text-red"></i>Members need to Checkin before 11AM
            </span>}
          </>
        )}
        {action && <span className="w3-margin-left">{action.action} 
          <span className="w3-text-aqua w3-padding-small">({moment.unix(action.action_time/1000).fromNow()}
        )</span></span>}
      </div>
      :<span className="w3-padding-64 w3-center">...</span>}

    </Fragment>
  );
}

export default withRouter(UsersStatus);
