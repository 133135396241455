import React, { Component, useEffect, useState } from 'react'
import moment from 'moment';
import { Link, withRouter } from 'react-router-dom';
import { Spinner, TabFilter } from '../Helper';
import { formatSecs, paginatedFetch, getIssueProject } from '../../utils'
import MilestoneSwitch from '../perf/MilestoneSwitch';
import IssueGroup from '../perf/IssueGroup';
import IssueBoard from '../widgets/IssueBoard';
import Header from '../widgets/Header';
import { ProjectContrib } from '../widgets/ProjectContrib';

const Member = (props) => {

    let { user, match: { params: { uname, milestone = 'CurrentSprint', filter = 'board' } } } = props;

    const [issueList, setIssueList] = useState([]);
    const [projectList, setProjectList] = useState(null);
    const [btnDisabled, setBtnDisabled] = useState(true);
    const processIssues = (list, meta) => {
        const map = list.reduce((acc, item) => {
            const uname = getIssueProject(item);
            if (!acc[uname]) {
                acc[uname] = new IssueGroup(uname, uname, 'project');
            }
            acc[uname].addIssue(item);
            return acc;
        }, {});
        setIssueList(list);
        setProjectList(Object.values(map).sort((a, b) => a.totalEstimate - b.totalEstimate));

        setBtnDisabled(false);
    }

    const fetchData = (mode = '') => {
        setProjectList(null);
        setBtnDisabled(true);
        if (!uname) {
            uname = user?.username;
        }

        if (!milestone) {
            milestone = 'CurrentSprint';
        }
        paginatedFetch('/api/v1/issues', {
            page: 1,
            mode,
            uname,
            milestone
        }).then(resp => {
            processIssues(resp.data, resp.meta);
        }).catch(err => {
            console.error(err);
            alert(err.meta.message);
            document.location.href = "/sprint"
        });
    }

    useEffect(() => {
        fetchData();
    }, [milestone])
    let count = 0;
    let content = null;
    let totalEstimate = 0;
    let totalSpent = 0;

    if (!uname) {
        uname = user?.username;
    }
    if (projectList == null) {
        content = <div className="w3-center"><Spinner /></div>;
    } else if (filter == 'contrib') {
        content = <ProjectContrib />
    } else if (filter == 'board') {
        content = <IssueBoard
            issues={issueList}
            mode='user'
            id={uname} />
    } else {
        content = projectList.reduce((acc, item) => {
            const view = item.renderView(filter);
            if (item.filtered > 0) {
                acc.push(view);
                count = item.filtered
            }
            return acc;
        }, []);
        count = projectList.reduce((acc, item) => acc + item.filtered, 0);
        if (content.length == 0) {
            content = <div className="w3-center w3-light-grey w3-padding-64"><h6>Empty List</h6></div>
        } else {
            content = <div className="w3-responsive">
                <div className="cgs-table" style={{ minHeight: "100vh" }}>
                    {content}
                </div>
            </div>
        }
    }

    if (issueList) {
        totalEstimate = issueList.reduce((acc, i) => acc + i.timeEstimate, 0);
        totalSpent = issueList.reduce((acc, i) => acc + i.timeSpent, 0);
    }

    const spanItem = <div className="w3-white w3-round w3-maring-left w3-display-inline-block"
        style={{
            marginLeft: "10px",
            padding: "0px 10px"
        }}
    >{`${formatSecs(totalSpent)} / ${formatSecs(totalEstimate)}`}</div>


    return (
        <div >
            <Header
                icon="ion-person"
                title={`${uname}`}
                leftInfo={spanItem}
                leftLink={`https://gitlab.ceegees.in/groups/ceegees/-/boards?scope=all&utf8=%E2%9C%93&state=opened&milestone_title=${milestone}&assignee_username=${uname}`}
            />
            <div className="w3-bar cgs w3-theme-d2">
                <div className="w3-left">
                    <TabFilter base={`/member/${uname}/${milestone}`}
                        filters={['Board', 'All', 'Plan', 'Open', 'Spill', 'Done', 'Contrib']}
                        current={filter}
                        count={count}
                    />
                </div>
                <div className="w3-right">
                    <button disabled={btnDisabled}
                        onClick={e => fetchData('refresh')}
                        className="w3-bar-item w3-blue w3-button">
                        <i className="ion-refresh"></i>
                    </button>
                    <MilestoneSwitch
                        urlScheme={`/member/${uname}/MILESTONE/${filter}`} />
                </div>
            </div>
            <div className="w3-row">
                {content}
            </div>
        </div>
    )

}

export default Member;
