import React, {Component} from 'react'; 
import {Link,withRouter} from 'react-router-dom'; 
import moment from 'moment'; 
import {connect} from 'react-redux';  

class MilestoneSwitch extends Component {
    render() {
    
        const {milestones,match:{params:{milestone}}} = this.props;
        if (!milestones) {
            return null;
        }
        const ms = milestones.find(item => item.title == milestone); 
        let milestoneName = 'CurrentSprint';
        if (ms) {
            milestoneName = ms.title;
        }
        return <div className="w3-dropdown-hover" style={{position:"relative"}} >
            <button className="w3-button w3-indigo">{milestoneName}
                <span className="ion-chevron-down" style={{
                    fontSize: "10px",
                    marginLeft: "10px"
            }}></span>
            </button>
            <div className="w3-dropdown-content w3-bar-block w3-border" 
                style={{right:"0px",height:"400px",maxHeight:'400px',width:'240px',overflow:'auto'}}>
                {milestones.map(item => {
                    return <Link  key={item.id} 
                        to={this.props.urlScheme.replace('MILESTONE',item.title)} className={`w3-bar-item cgs-list-milestone  w3-button ${item.state =='active' ? '':'w3-blue-grey'} `}>{item.title}
                       <span className="w3-tiny  w3-right w3-purple w3-round" title={`${item.start_date} - ${item.due_date}`}>{moment(item.start_date).format("DD")}-{moment(item.due_date).format("DD")}</span>
                    </Link>
                })}
            </div>
        </div>   
    }
} 
const mapState2Props = (state) => {
    return {
        milestoneName: state.routeParams.milestoneName,
        milestones: state.routeParams.milestoneList
    }
}
export default withRouter(connect(mapState2Props)(MilestoneSwitch));