import React, {Component} from 'react'  
import {withRouter,Link} from 'react-router-dom'; 
import {iAxios} from '../utils';

const $ = window.$;

String.prototype.replaceAll = function(search, replacement) {
  var target = this;
  return target.replace(new RegExp(search, 'g'), replacement);
};

const statusList = [
    "ACCEPT",
    "REJECT",
    "NOT_RECHABLE",
    "PEDNING",
    "SHORTLIST"  
];

const DetailModal = ({resume,handleHide}) => (
      <div id="product-modal" className="w3-modal w3-show">
        <div className="w3-modal-content">
          <div className="w3-container">
              <span className="modal-close w3-hover-blue w3-button w3-display-topright w3-teal" onClick={e => handleHide()}>
              <i className="ion-close"></i>
          </span>
          <div id="modal_content">
          <div className="w3-padding-64">
          <div dangerouslySetInnerHTML={{
            __html:resume.resume && resume.resume 
              .replaceAll('style=.*display.*none','')
          }} >
          </div>
          </div>
          </div>
          </div>
        </div>
    </div>
)
class ResumeBlock extends Component {

  constructor(props){
    super(props);
    this.state = {
      resume:props.resume
    };
  }

  updateStatus(evt){
    evt.preventDefault();
    iAxios.post('/api/v1/scrap-update',{ 
      id:this.state.resume.id,
      status:this.refs.option_ref.value
    }).then(resp => {
      this.setState({
        resume:resp.data
      })
    });
  }
  render() {
    const { resume } = this.state;
    const {showModal} = this.props;
    return <div className="w3-margin w3-light-grey  w3-border-bottom w3-border w3-padding">
      <button className="w3-button w3-right w3-blue" onClick={e => showModal(resume)}>Open</button>
      <div  dangerouslySetInnerHTML={{
      __html:resume.summary && resume.summary 
        .replaceAll('style=.*display.*none','')
    }}></div>
    <form onSubmit={this.updateStatus.bind(this)} className="w3-row w3-padding">
      <div className="w3-col m4 ">
        <b className="w3-padding w3-yellow w3-block w3-center">{resume.status}</b>
      </div>
      <div className="w3-col m4 w3-padding">
        <select ref="option_ref" className="w3-select w3-padding">
          {statusList.map(i => <option value={i}>{i}</option>)} 
        </select>
      </div>
      <div className="w3-col m4 "><button className="w3-block w3-button w3-green">Submit</button></div>
    </form>
    </div>
  }
}

class ResumeSearch extends Component{
    constructor(arg){
        super(arg);
        this.state = {
            resumeList:[],
            total:0,
            modal:null
        }
        this.showModal = this.showModal.bind(this);
    } 
    showModal(item) {
      if (!item){
        return this.setState({
          modal:null
        });
      }
      this.setState({
        modal:<DetailModal resume={ item } handleHide={this.showModal} />
      });
    }
    searchRes(e){
      e.preventDefault();
      this.setState({
        resumeList:[]
      });
      iAxios.get('/api/v1/scrap',{
        params:{
          r:$("#resume_text").val(),
          s:$("#summary_text").val(),
          f:$("#filter").val(),
        }
      }).then(res => {
        this.setState({
          total:res.data.total,
          resumeList:res.data.result
        });
      })
    }
    render(){

        const {resumeList} = this.state;
        const list = resumeList.map(item => { 
          return <ResumeBlock resume={item} 
          showModal={this.showModal}/>
        })
        return <div >
            <div className="w3-deep-purple w3-container">
                <Link to="/home"><h3>Home</h3></Link>
            </div>
            <div className="w3-bar cgs w3-theme-d2"> 
              <form className="w3-row-padding w3-padding" onSubmit={this.searchRes.bind(this)}>
                 <div className="w3-col m4 ">
                    <input type="text"  placeholder="Resume" id="resume_text" className="w3-input"  />
                 </div>
                 <div className="w3-col m4 ">
                    <input type="text" placeholder="Summary" id="summary_text" className="w3-input"  />
                 </div>
                 <div className="w3-col m2 w3-padding">
                  <select id="filter" className="w3-select w3-input w3-padding">
                      <option value="ACTIVE">ACTIVE</option>
                      <option value="ALL">All</option>
                      {statusList.map(i => <option value={i}>{i}</option>)} 
                    </select>
                 </div>
                 <div className="w3-col m2 ">
                  <button className="w3-button w3-blue w3-round w3-block">Search</button>
                 </div>
                 </form>
            </div> 
            <div className="w3-responsive">
              <div className="w3-padding"><b>Total : {this.state.total} </b></div>
              <div>
                  {list}
              </div>
              {this.state.modal}
            </div>
        </div>
    }
}

export default withRouter(ResumeSearch);