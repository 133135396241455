import React, {Component} from 'react'  
import {Link,withRouter} from 'react-router-dom'; 
import {Spinner} from '../Helper';
import {formatSecs,paginatedFetch,fetchData, iAxios } from '../../utils'  
import ReactMarkdown  from 'react-markdown';
import Header from '../widgets/Header';

class Poker extends Component{
    constructor(arg){
        super(arg);
        this.state = {
            issueList:null,
            plan:null,
            errorMessage:null
        }
        this.timeInterval = null;
    } 

    fetchData(force = 0){
        return fetchData(`/api/v1/poker-play`,{
            force:force,
            project:this.props.match.params.product,
        }).then(resp =>{ 
            this.setState({plan:resp.data.data});
            return resp.data;
        }).catch(ex =>{
            console.error("Error XXX:",ex);
            alert(ex.message);
            document.location.reload();
        });
    }

    fetchIssues(mode = 'normal') {
        this.refs.reload_btn.disabled = true;
        this.setState({
            issueList:null,
            plan:null,
            errorMessage:null
        });

        paginatedFetch('/api/v1/issues',{
            project:this.props.match.params.product,
            mode:mode,
            // state:'open',
            milestone:'all',
            // state:'open'
        }).then(res=>{ 
            this.setState({issueList:res.data.sort((a,b)=> a.weight - b.weight)});
            this.fetchData();
            this.refs.reload_btn.disabled = false;
        }).catch(ex => {
            console.error('Error : Poker: 51 ',ex);
            alert(ex.message);
        })
    }

    componentDidMount(){
       this.fetchData().then(data =>{
            this.fetchIssues();
       })
       this.timeInterval = setInterval(()=>{
            this.fetchData();
       },5000);
    }  
    componentWillUnmount(){
        if(this.timeInterval){
            clearInterval(this.timeInterval);
        }
    }

    setError(result){
        if (result.meta.status){
            return;
        }
        this.setState({
            errorMessage:result.meta.message
        });
        setTimeout(()=>{
            this.setState({
                errorMessage:null
            });
        },5000)
    }
   
    playPoker(value){
        iAxios.post('/api/v1/poker-issue',{
            value:value,
            project:this.props.match.params.product,
        }).then(resp => {
            this.setError(resp.data);
            this.setState({plan:resp.data.data});
        });
    }

    handleIssue(issue){
        iAxios.post('/api/v1/poker-issue',{
            issue:issue,
            project:this.props.match.params.product,
        })
        .then(resp => {
            const result = resp.data; 
            this.setError(result);
            this.setState({plan:resp.data.data});
        }).catch(ex => {

        })
    }
    renderIssue(){
        const {plan,errorMessage} = this.state; 
        if (!plan){
            return null;
        }
        const vals = [1,2,3,5,8,13,21];

        return (<div className="w3-padding">
            {errorMessage ? <div className="w3-red w3-padding">{errorMessage}</div>:null}
                <table className="w3-table text-align-left w3-table-all">
                    <thead>
                        <tr>
                            <td>Player</td>
                            <td>Estimate</td>
                        </tr>
                    </thead>
                <tbody>
                    {plan.poker && Object.keys(plan.poker).map(email   =>
                        <tr key={email}>
                        <td>{email}</td>
                        <td>{plan.poker[email]}</td>
                    </tr>) }
             </tbody>
             </table>
             <div  className="w3-margin-top" >
                {plan.poker ? <button onClick={this.fetchData.bind(this,1)} className="w3-button w3-teal w3-block">Refresh</button> : null}
                { !plan.poker && plan.issue && vals.map(item => {
                    return <button  key={item} value={item} 
                    onClick={this.playPoker.bind(this,item)}
                    style={{margin:"8px"}}
                        className="w3-button w3-center w3-green  " style={{width:"12%",
                        padding:"16px 8px",
                        textAlign:"center",
                        marginLeft:"3px"}} >
                        {item}
                    </button>
                })
                }
            </div>
            {plan.issue ? 

            <div><a href={plan.issue.url} target="_blank"> <h4>{plan.issue && plan.issue.title}</h4></a>
            <div className="cgs-markdown">{plan.issue && <ReactMarkdown source={plan.issue.description} /> }</div>
            </div> : <h4> Select An issue </h4>
            }
        </div>)

    }
    render(){

        const {issueList,plan} = this.state; 
       const refreshButton = (<div className="w3-padding w3-right">
           <button onClick={this.fetchIssues.bind(this,'refresh')} 
            ref="reload_btn" 
            className="w3-right w3-small w3-round w3-blue w3-button">
                <i className="ion-refresh"></i>
            </button>
        </div>);

        return <div >
            <Header title="Planning Poker" leftItem={refreshButton}/> 
            <div className="w3-responsive">
                {issueList ? null:<Spinner />}
                <div className="w3-row">
                    <div className="w3-col m6 w3-center">
                        {this.renderIssue()}
                    </div>
                    <div className="w3-col m6 w3-padding">
                        <table className="w3-table w3-table-all">
                            <tbody>
                            {this.state.issueList && this.state.issueList
                            .map((item,idx) => {
                                return <tr key={idx} style={{cursor:'pointer'}} >
                                    <td>{idx+1}</td>
                                    <td>
                                    <span onClick={this.handleIssue.bind(this,item) }> 
                                       {item.title} 
                                        <a target="_blank" className="w3-right" href={`${item.url}`}>Open</a>
                                       {item.weight && <span className="cgs-small-tag w3-blue weight w3-right">{item.weight}</span> } 
                                       {item.labels.map(lb => <span key={lb} className="cgs-small-tag w3-green w3-right"> {lb}</span>)}
                                       {item.timeEstimate > 0 && <span className="cgs-small-tag w3-blue-grey w3-right">{formatSecs(item.timeEstimate)}</span>}
                                    </span>
                                    </td>
                                </tr>
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default withRouter(Poker);