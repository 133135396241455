import React, {Component} from 'react' 
import moment from 'moment';
import {iAxios} from '../utils';
import {Spinner} from './Helper';
import {Link, withRouter} from 'react-router-dom';


const $ = window.$;

class ItemRow extends Component{
    constructor(arg){
        super(arg)
        this.state = {
            status : 'inactive'
        }
    }
    toggleSatus(){
        this.setState({status: this.state.status == 'inactive'?'active':'inactive'})
    }
    componentWillReceiveProps(nextProps){
        if (nextProps.item.Row != this.props.item.Row){
            this.setState({status:'inactive'});
        }
    }
    updateRow(e){
        this.setState({status:'loading'})
        this.props.updateRow(e).then((item)=>{
            this.setState({status:'inactive'});
        });
    }

    render(){
        const {item,idx,month} = this.props;
        let cls = 'w3-khaki';
        if (item.ReviewStatus > 0){
            cls = '';
        }
        let formCls = 'w3-hide';
        if (this.state.status == 'active'){ 
            formCls = 'w3-show';
        } 
        

        let content = <form className={`w3-row-padding w3-margin-top`} 
                onSubmit={this.updateRow.bind(this)}>
                <input type="hidden" name="row" value={item.Row}/>
                <input type="hidden" name="mon" value={month} />
                <div className={`${formCls}  w3-col m8 s12`}>
                    <input className="w3-input w3-border" type="text" 
                    name="MessageStatus"
                    defaultValue={item.MessageStatus} placeholder="Message, [MailSent] to Close"/>
                </div>
                <div className={`${formCls}  w3-col m1 s4`}>
                    <input className="w3-input w3-border" type="text" 
                    name="ReviewStatus"
                    defaultValue={item.ReviewStatus}
                    placeholder="Review Rating (-10 to 10)" type="number" min="-10" max="10"/>
                </div>
                <div className={`${formCls} w3-col m2 s7`}>
                    <button className="w3-button w3-green w3-block">Save</button>
                </div>
                <div className="w3-col m1 s1 w3-right">
                <button type="button" className="w3-button w3-block w3-right w3-blue-grey" 
                    onClick={this.toggleSatus.bind(this)}> { this.state.status == 'active' ? 'Cancel' : 'Update' }</button>
                </div>
        </form>

        if (this.state.status  == 'loading') {
            content = <Spinner message='Updating data' />
        }
        return <div className={`w3-border w3-panel w3-padding ${cls}`} key={item.Id} 
            >
            <a target="_blank" href={`https://docs.google.com/spreadsheets/d/1MbCBl5AYs3PgdPcrIz6nFR98y3acUIa73Ba_Wh2EmEc/edit#gid=343477502&range=E${item.Row}`}><h5>{idx+1} ) {item.Tags} | {`Points_${item.ReviewStatus}`} | {item.MessageStatus} | {moment(item.Time).fromNow()} | Row {item.Row}</h5></a>
            <div style={{   whiteSpace: 'pre-wrap'   }}>{item.Description}</div>
            {content}
        </div>
    }
}

class Leads extends Component{

    constructor(arg){
        super(arg);
        this.state = {
            leads:null,
            filter:null
        }
    }
    loadData(){ 
        return iAxios.get(`/api/v1/leads?mon=${this.props.match.params.month}`)
        .then((resp)=>{
           this.setState({leads:resp.data});
        })
        .catch((error)=> {
            console.error('Error : ',error);
        });

    }
    componentDidMount(){
        if (this.props.match.params.month == undefined){
            document.location.href = '/leads-xyz/Jul';
        }
        this.loadData();
    }
    handleFiter(e){
        this.setState({filter:e.target.value});
    }
    updateRow(e){
        e.preventDefault();
        e.stopPropagation();
        const params = $(e.target).serialize();
        return iAxios.post('/api/v1/leads',params).then(resp => { 
            return this.loadData();
        });
    }
    render(){

        if (this.state.leads == null){
            return <Spinner />;
        }
        const leads= this.state.leads.sort((a,b) =>{
            return b.ReviewStatus - a.ReviewStatus;
        });
        return <div className="w3-container" id="leads">
            <div className="w3-row">
                <div className="w3-col m4">
                <h4 >Leads</h4>
                </div>
                <div className="w3-col m4 w3-right">
                <input style={{outline:0}} onChange={this.handleFiter.bind(this)} 
                placeholder="search / filter"
                className="w3-input" />
                </div>
            </div>
            {
                
                leads.filter(item => this.state.filter == 'all'  
                    ||  item.ReviewStatus >= 0)
                .filter(item=> !this.state.filter 
                    || this.state.filter == 'all' 
                    || item.Description.indexOf(this.state.filter) != -1)
                .filter(item => this.state.filter == 'all' 
                    || item.MessageStatus.indexOf('MailSent') == -1 ) 
                .map((item,idx) => <ItemRow 
                    key={`row_${idx}`} 
                    item={item} idx={idx} 
                    updateRow={this.updateRow.bind(this)}
                    month={this.props.match.params.month} />)
            }
        </div>
    }
}

export default withRouter(Leads);