const { useSelector, useDispatch } = require("react-redux");

export const ClearFilterButton = ({ clsName }) => {
    const userFilter = useSelector(state => state.filters.userList); 
    const dispatch = useDispatch();
    const clearFilter = () => {
        dispatch({
            type: 'filter_users',
            users: [],
        })
    }

    return <span className={`w3-dropdown-hover `}>
        <button className={`w3-button  `}
            title={`Remove filter ${userFilter.map(item => item.name).join(',')}`}
            disabled={userFilter.length == 0}
            onClick={clearFilter}
        >
            {/* <i className='ion-android-funnel w3-text-black'  /> */}
            <i className="ion-ios-people" />
        </button>
        {userFilter.length > 0 && <div className="w3-dropdown-content w3-bar-block w3-border w3-padding">
            Remove filtering on
            <ol className="w3-list">
                {userFilter.map((item) => (<li>{item.name}</li>))}
            </ol>
        </div>}
    </span>
}
