import React, { useEffect, Component, useState } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { formatSecs, COLORS, iAxios, roundUpHour, getDiffClass, getIssueStats, getMilestone } from '../../../utils';
import BurnDown from '../../perf/BurnDown';
import {SprintBacklog,TodaysProgress} from '../../widgets/SprintBacklog';
import { connect } from 'react-redux';

import Leads, { BlogStatus } from './Leads';
import ProductBacklog from './ProductBacklog';
import MemberStats from './MemberStats';

class Dashboard extends Component {
    constructor(args) {
        super(args);
        this.state = {
            sprintStatus: []
        };
    }


    render() {
        const { issueList, userList, routeParams } = this.props;
        if (issueList.length === 0) {
            return <div>Milestone have Zero issues</div>
        }
        const milestone = getMilestone();
        const colorMap = {};
        const {
            sprintStatus,
            completionStatus,
            tmTotal,
            prog,
            progVal
        } = getIssueStats(issueList, milestone);


        return <div>
            <div className="w3-row-padding  w3-margin-top">

                <div className="w3-col l3 m5 s12 w3-margin-bottom">
                    <Leads userList={userList} milestone={milestone.title} />
                    <BlogStatus userList={userList} />
                </div>
                <div className="w3-col l5 m7 s12">
                    <div className="w3-margin-bottom">
                        <MemberStats userList={userList}
                            milestone={milestone}
                            tmTotal={tmTotal}
                            prog={prog}
                            progVal={progVal} />
                    </div>
                    <div className="w3-margin-bottom" >
                        <BurnDown issueList={issueList} />
                    </div>
                </div>
                <div className="w3-col w3-row-padding l4 m12  s12">
                <div className="w3-col l12 m6 w3-margin-bottom">
                        <TodaysProgress />
                    </div>
                    <div className="w3-col l12 m6 w3-margin-bottom">
                        <SprintBacklog sprintStatus={sprintStatus}
                            prog={prog}
                            milestone={milestone.title}
                            ASSIGNMENTS={colorMap}
                            completionStatus={completionStatus} />
                    </div>
                    <div className="w3-col l12 m6 w3-margin-bottom">
                        <ProductBacklog
                            ASSIGNMENTS={colorMap} />
                    </div>

                </div>
            </div>
        </div>
    }
}

const mapSt2Pr = (state) => {
    return {
        milestoneList: state.milestones,
        routeParams: state.routeParams
    }
}
export default connect(mapSt2Pr)(Dashboard)
