

import React, {Component} from 'react';
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,PieChart,Pie,Cell} from 'recharts'; 
import { calcBurnDown, formatDay, getMilestone } from '../../utils'; 
import {ButtonGroup} from '../Helper';
import {connect} from 'react-redux'; 
class BurnDown extends Component {
    constructor(args){
        super(args);
        this.state = {
            mode:'Count'
        }
    } 
    formatTick(lab){
        if (this.state.mode == 'Time'){
            return formatDay(lab);
        }
        return lab;
    }
    modeChange(mode){ 
        this.setState({mode:mode});
    }
    render() {
        
        const selectedMilestone = getMilestone();
        const buttons = ['Count','Weight','Time'];
        const {mode} = this.state;
        const chartData = calcBurnDown(this.props.issueList,
            mode.toLowerCase(),
            selectedMilestone
        );
        return <div className="w3-border w3-white w3-responsive"> 
            <h4 className="w3-center">Sprint BurnDown </h4>
            <ButtonGroup buttons={buttons} selected={mode} onSelect={this.modeChange.bind(this)} />
            <ResponsiveContainer  width='100%' aspect={20.0/9.0}>
                <LineChart margin={{top:40,left:40,right:40,bottom:40}}  data={chartData}>
                    <XAxis dataKey="name"/>
                    <YAxis tickFormatter={this.formatTick.bind(this)} />
                    <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                    <Line type="monotone" name="Open" dataKey="open" stroke="#4CAF50"   />
                    <Line type="monotone" name="Added" dataKey="added" stroke="#0000d8" />  
                    <Line type="monotone" strokeDasharray="5 5"  
                        activeDot={{r: 4}} name="Ideal" dataKey="ideal" stroke="#777777" /> 
                    <Tooltip formatter={this.formatTick.bind(this)}/>
                </LineChart>
            </ResponsiveContainer>
        </div>
    }
}


const mapStateToProps = (state) => { 
    return {
        milestoneList : state.milestones
    }
}

export default connect(mapStateToProps)(BurnDown);