
import moment from 'moment';
import axios from 'axios';
import qs from 'querystring';
import { actionClearStorage } from './redux/actions';

axios.interceptors?.request.use(function (config) {
    config.headers['access-token'] = localStorage.getItem('access_token');
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});
axios.interceptors?.response.use(function (result) {
    return result;
}, function (error) {
    if (error.response.status == 401){
       
        if (localStorage.getItem('access_token')){
            console.log('CLEANUP');
            actionClearStorage(); 
            document.location.href = '/'
        }
    }
    // Do something with request error
    console.log(error);
    return Promise.reject(error);
});
export const iAxios = axios;

export function getIssueProject(issue) {
    return issue.project.name;
}

export function getMilestoneName() {
    const state = window.gAppStore.getState();
    return state.routeParams.milestoneName ? state.routeParams.milestoneName : 'CurrentSprint';
}

export function getMilestone() {
    const state = window.gAppStore.getState();
    const { milestone, milestoneName } = state.routeParams;
    if (!milestone && milestoneName) {
        const parts = milestoneName.split('_');
        return {
            name: milestoneName,
            start: parts[0],
            end: parts[1]
        };
    }
    return milestone;
}

export function roundUpHour(secs) {
    let offset = secs % 3600;
    if (offset) {
        offset = 3600 - offset;
    }
    return secs + offset;
}

export const COLORS = [
    '#0088FE', '#00C49F', '#FFBB28', '#FF8042',
    '#ff0066', '#330099', '#00bfff', '#CD5C5C',
    '#0088AA', '#FF9900', '#CC99FF', '#6666CC',
];


export function getDiffClass(diff, vals = [30, 20, 10]) {
    let cls = 'w3-green';
    if (diff > vals[0]) {
        cls = 'w3-red';
    } else if (diff > vals[1]) {
        cls = 'w3-orange';
    } else if (diff > vals[2]) {
        cls = 'w3-yellow';
    }
    return cls;
}

export function filterIssues(issues, filter) {
    var filterList = [];
    const stInProgress = "1.InProgress",
        stDevCompleted = "2.DevCompleted",
        stQaReady = "3.QaReady",
        stQaVerfied = "4.QaVerified",
        stVerifyOnProd = "5.VerifyOnProd";
    const progressLabels = [
        stInProgress,
        stDevCompleted,
        stQaReady,
        stQaVerfied,
        stVerifyOnProd
    ];

    if (filter === 'worked') {
        filterList = [stDevCompleted]
    } else if (filter === 'split' || filter === 'spill') {
        filterList = ["TaskSplit"];
    }
    const toRemove = progressLabels.concat(['CurrentSprint']);
    return issues.filter((item) => {
        if (filter === 'all') {
            return true;
        }
        //backlog > working > dev_compelte > testing > verified > closed
        if (filter === 'backlog') {
            return item.state === 'open';
        } else if (filter === 'completed' || filter === 'done') {
            return item.state === 'closed';
        } else if (filter === 'open') {
            return item.state !== 'closed';
        } else if (filter === 'plan') {
            return item.timeEstimate == null;
        } else if (filter === 'qa') {
            return item.state === 'testing';
        } else if (filter === 'dev') {
            return item.state === 'working';
        } else if (filter === 'worked') {
            return item.state === 'dev_complete';
        } else if (filter === 'ops') {
            return item.state === 'verified';
        }
        return item.labels.some(label => filterList.indexOf(label) >= 0);
    }).map(i => Object.assign(i, { labelsToRemove: toRemove }));
}

export function timeStats(issue) {
    return [
        issue.timeSpent === null ? '-' : formatSecs(issue.timeSpent),
        " / ",
        issue.timeEstimate === null ? '-' : formatSecs(issue.timeEstimate)
    ].join('');
}

export function calcSprintStatus(issueList) {

    const map = issueList.reduce((acc, item) => {
        const project = getIssueProject(item);
        if (!acc[project]) {
            acc[project] = {
                total: 0,
                name: project,
                open: 0,
                timeSpent: 0,
                timeEstimate: 0,
                closedEst: 0,
                selfClosed: 0,
                perc: 0.0
            }
        }
        if (item.state != 'closed') {
            acc[project].open++;
        } else {
            acc[project].closedEst += item.timeEstimate;
        }
        acc[project].timeSpent += item.timeSpent;
        acc[project].timeEstimate += item.timeEtimate !== null ? item.timeEstimate : 0;
        acc[project].total++;
        if (item.closedBy && item.assignee && item.assignee.username === item.closedBy.username) {
            acc[project].selfClosed++;
        }
        acc[project].perc = ((acc[project].total - acc[project].open) * 100 / acc[project].total);
        return acc;
    }, {});

    const sprintStatus = Object.keys(map).map(name => map[name]);
    sprintStatus.sort((a, b) => b.total - a.total);
    return sprintStatus;
}

export function calcBurnDown(issueList, mode = 'count', milestone) {

    let total = issueList.length;

    if (mode === 'weight') {
        total = issueList.reduce((acc, item) => acc + (item.weight ? item.weight : 0), 0);
    } else if (mode === 'time') {
        total = issueList.reduce((acc, item) => acc + item.timeEstimate, 0);
    }

    const chartData = [];
    const start = moment(milestone.start);
    const end = moment(milestone.end);

    if (!start.isValid()) {
        throw new Error('invalid start for milestone');
    }
    const days = workingDays(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'));
    const pFactor = (total / (days - 1));
    const now = moment();
    let ideal = total;
    while (true) {
        const day = start.format('dddd');
        if (day === 'Sunday' || day === 'Saturday') {
            start.add(1, 'Days');
            continue;
        }
        const red = issueList.reduce((acc, item) => {
            if (item.completedAt && moment(item.completedAt) <= start) {
                if (mode === 'count') {
                    acc.open--;
                } else if (mode === 'weight') {
                    acc.open -= item.weight ? item.weight : 0;
                } else if (mode === 'time') {
                    acc.open -= item.timeEstimate;
                }
            }
            if (moment(item.createdAt) < start) {
                if (mode === 'count') {
                    acc.added++;
                } else if (mode === 'weight') {
                    acc.added += item.weight ? item.weight : 0;
                } else if (mode === 'time') {
                    acc.added += item.timeEstimate;
                }
            }
            return acc;
        }, {
            open: total,
            added: 0,
            ideal: Math.floor(ideal),
            name: start.format("YYYY-MM-DD")
        });

        ideal -= pFactor;
        if (ideal < 0) {
            ideal = 0;
        }
        start.add(1, 'Days');
        chartData.push(red);
        if (start <= end || ideal > 0) {
            continue;
        } else if (red.open == 0) {
            break;
        } else if (start.endOf('day') > now.endOf('day')) {
            break;
        }
    }
    return chartData;
}
export function getIssueStats(issueList, milestone) {

    const total = issueList.length;
    if (total === 0) {
        return {};
    }

    const sprintStatus = calcSprintStatus(issueList);
    const completionStatus = sprintStatus.reduce((acc, item) => {
        acc.push({ name: "closed", value: item.total - item.open });
        acc.push({ name: "open", value: item.open });
        return acc;
    }, []);

    const hoursProgress = hoursProgressed(milestone.start, milestone.end);
    const days = workingDays(milestone.start, milestone.end);
    const tmTotal = days * 8 * 3600;

    const res = {
        prog: Math.min(100, (hoursProgress * 100 / (days * 8)).toPrecision(4)),
        progVal: Math.min(hoursProgress * 3600, tmTotal),
        tmTotal: tmTotal,
        sprintStatus: sprintStatus,
        completionStatus: completionStatus
    }
    return res;
}

export function fetchData(url, params) {
    const query = qs.stringify(params);
    return iAxios.get(`${url}?${query}`);
}

export function paginatedFetch(url, qparams = {}) {
    function getData(params, context) {
        if (!context.data) {
            context.data = [];
        }
        const query = qs.stringify(params);
        iAxios.get(`${url}?${query}`).then((resp) => {
            if (resp.data.meta.status != true) {
                context.reject(resp.data);
                return;
            }
            context.data = context.data.concat(resp.data.data);
            if (resp.data.data.length === 100) {
                params.page = params.page + 1;
                params.mode = 'normal';
                getData(params, context);
            } else {
                context.resolve({
                    meta: resp.data.meta,
                    data: context.data
                });
            }
        });
    }

    if (!qparams.page) {
        qparams.page = 1;
    }

    return new Promise((resolve, reject) => {
        getData(qparams, {
            resolve: resolve,
            reject: reject,
            data: []
        });
    });
}


export const formatDay = (secs) => {
    if (secs == 0) {
        return "0 days";
    }
    var days = Math.floor(secs / (3600 * 8));
    secs -= days * 3600 * 8;
    var hrs = Math.floor(secs / 3600);
    secs -= hrs * 3600;
    var mins = Math.floor(secs / 60);
    secs -= mins * 60;
    const final = [];
    if (days > 1) {
        final.push(`${days} days`)
    } else if (days > 0) {
        final.push(`${days} day`)
    }

    if (hrs > 1) {
        final.push(`${hrs} hrs`)
    } else if (hrs > 0) {
        final.push(`${hrs} hr`)
    }

    if (mins > 0) {
        // final.push(`${mins} mins`)
    }
    return final.join(' ');
}

export const formatSecs = (secs, roundUpMode = 'secs') => {
    if (secs === 0) {
        return "0h";
    }
    if (roundUpMode === 'hour') {
        secs = roundUpHour(secs);
    }
    const mins = Math.floor((secs % 3600) / 60);
    let str = "";
    if (mins != "0") {
        str = mins + "m";
    }
    const hr = Math.floor(secs / 3600);
    if (hr > 0) {
        str = hr + "h " + str;
    }
    return str;
}
export function workingDays(startDt, endDt, log = false) {
    const end = moment(endDt);
    const iter = moment(startDt);
    let days = 0;
    while (iter <= end) {
        const da = iter.format('dddd');
        if (['Saturday', 'Sunday'].indexOf(da) < 0) {
            days++;
        }
        if (log) {
            console.log(days, da, startDt, iter.format("YYYY-MM-DD"), end.format("YYYY-MM-DD"));
        }
        iter.add(1, 'Days');
    }
    if (log) {
    }
    return days;
}

export function hoursProgressed(startDt, endDt) {
    const end = moment();
    const iter = moment(startDt);
    let hours = 0;
    const cap = moment(endDt + " 24:00:00");
    while (iter <= end && iter <= cap) {
        iter.add(1, 'hours');
        if (['Saturday', 'Sunday'].indexOf(iter.format('dddd')) >= 0) {
            continue;
        }
        const hr = iter.hour();
        if (hr < 9 || hr > 18 || hr === 12 || hr === 13) {
            continue;
        }
        hours += 1;
    }
    return hours;
}

