
import React, { useEffect } from 'react';
import { Route, Router, Switch, withRouter, useHistory } from 'react-router-dom'
import { connect } from 'react-redux';
import Leads from './Leads';
import ResumeSearch from './ResumeSearch';
import Status from './pages/Status';
import Member from './pages/Member';
import Project from './pages/Project';
import Poker from './pages/Poker';
import Client from './pages/Client';
import Login from './pages/Login';
import Room from './pages/Room';
import { Jira } from './perf/Jira';
import { fetchMilestones, fetchUserInfo, setMilestoneName } from '../redux/actions';

const routes = [{
    exact: true,
    path: "/leads-xyz/:month?",
    component: Leads
},
{
    path: "/login/:provider",
    component: Login,
},
{
    exact: true,
    path: "/res",
    component: ResumeSearch
},
{
    exact: true,
    path: "/jira/:projectId/:componentId?/:page?",
    component: Jira
},
{
    exact: true,
    path: "/room/:id?/:mode?",
    component: Room
},
{
    exact: true,
    path: "/project/:project/:milestone?/:filter?",
    component: Project
}, {
    exact: true,
    path: "/client/:clientId/:milestone?/:filter?",
    component: Client
},
{
    exact: true,
    path: "/member/:uname/:milestone?/:filter?",
    component: Member
},
{
    exact: true,
    path: "/me",
    component: Member
},
{
    path: '/sprint/:milestone?/:filter?',
    component: Status
},
{
    path: "/poker/:product",
    component: Poker
},
{
    path: '/login',
    component: Login,
},
{
    path: '/',
    component: Status,
},
];



class _MilestoneHOC extends React.Component {
    componentDidUpdate(prevProps) {
        const { match: { params }, setMilestoneName } = this.props;
        if (params.milestone && prevProps.match.params.milestone !== params.milestone) {
            setMilestoneName(this.props.match.params.milestone);
        }
    }
    componentDidMount() {
        const { setMilestoneName } = this.props;
        if (this.props.match.params.milestone) {
            setMilestoneName(this.props.match.params.milestone);
        }
    }
    render() {
        return this.props.children;
    }
}
const MilestoneHOC = withRouter(_MilestoneHOC);

const App = (props) => {
    const history = useHistory();
    const { routeParams, user, match: { params }, milestones, setMilestoneName } = props;
    const userData = localStorage.getItem('user');
    useEffect(() => {
        if (!userData) {
            return;
        } 
        if (!milestones || milestones.length == 0) {
            props.fetchMilestones().then(() => {
                const info = JSON.parse(userData); 
            }) 
        }
    }, [milestones])
    useEffect(() => {
        if (!userData) {
            history.push('/login')
            return
        }
        props.fetchUserInfo().then(resp => {
            const data = resp.data.data;
            localStorage.setItem('user', JSON.stringify(data));
        });
    }, [])

    if (user && milestones.length == 0) {
        return <div className="w3-padding-64 w3-center">Loading Milestones..</div>
    }

    return (<Switch> {
        routes.map((r, idx) => {
            return <Route exact={r.exact}
                key={`route_${idx}`} path={r.path} render={props => {
                    return <MilestoneHOC {...props}
                        setMilestoneName={setMilestoneName}
                        match={props.match}>
                        <r.component {...props}
                            user={user}
                            routeParams={routeParams}
                            params={params} />
                    </MilestoneHOC>
                }}
            />
        })
    } </Switch>)

}

function mapStateToProps(state) {
    return {
        user: state.userInfo?.user,
        milestones: state.milestones,
        routeParams: state.routeParams
    }
}
export default withRouter(connect(mapStateToProps, {
    fetchMilestones,
    setMilestoneName,
    fetchUserInfo
})(App))

